import { ReactNode } from 'react';
import './Sticky.scss';

type Props = {
  children: ReactNode;
  height?: string;
};

export const Sticky = ({ children, height }: Props) => {
  return (
    <div className="test" style={height ? { height } : {}}>
      <div className="test__container" style={height ? { height } : {}}>
        <div className="test__el">{children}</div>
      </div>
    </div>
  );
};
